import dotProp from "dot-prop";
import React from 'react'
import { connect } from "react-redux";
import ui from 'redux-ui'
import { getPersistedEntities } from 'selectors/entities'
import { Button, Dropdown } from 'semantic-ui-react'
import { createEntityWithDefaults, updateEntity, upsertEntity } from "../../actions/entities";
import { actions } from "../../react-redux-form";
import DossierForm from "../dossiers/DossierForm";
import NewEntityModal from "../entities/NewEntityModal";

class GenerateConsultationErrorButtons extends React.Component {

  render() {
    if (this.props.ui.showDropdown) {
      return (
        <div>
          <Button.Group key='dropdown_button'>
            <Dropdown button search options={this.props.selectOptions}
                      onChange={this.props.handleChange}/>
            <Button icon={"checkmark"} color={"green"}
                    onClick={() => this.props.resolveError(this.props.selectedMatch)}/>
          </Button.Group>
          {' '}
          {this.props.matchType === 'dossier' ?
            <NewEntityModal entityName="dossier" form={DossierForm} key="new_client_button"
                            createEntityWithDefaults={this.props.createDossierWithDefaults}
                            trigger={(props) => {
                              return (
                                <Button onClick={props.onClick}>
                                  {I18n.t(`components.generate_consultation_errors_list_item.new_dossier`)}
                                </Button>)
                            }}
            />
            : null}
        </div>
      )
    } else {
      return (
        <Button onClick={() => this.props.updateUI('showDropdown', true)}>
          {I18n.t(`components.generate_consultation_errors_list_item.other`)}
        </Button>)
    }
  }

  componentDidMount() {
    this.props.updateUI('showDropdown', this.props.showDropDownImmediately);
  }
}

const mapStateToProps = (state, ownProps) => {

  let entityName, entityToText
  if (ownProps.matchType === 'dossier') {
    entityName = "dossier"
    entityToText = (dossier) => dossier.patient ? `${dossier.patient.firstName} ${dossier.patient.lastName}` : ''
  } else {
    entityName = "consultationType"
    entityToText = (consultationType) => `${consultationType.name}`
  }

  const entities = getPersistedEntities(entityName)(state, ownProps);
  const options = entities.map(ntt => {
    return {key: ntt.id, value: ntt.id, text: entityToText(ntt)}
  })


  const entityModel = `entities.generateConsultationError.byId.${ownProps.errorId}.matchId`
  const selectedMatchId = dotProp.get(state, entityModel)


  return {
    selectOptions: options,
    selectedMatch: selectedMatchId,
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  const entityModel = `entities.generateConsultationError.byId.${ownProps.errorId}.matchId`
  return {
    handleChange: (event, props) => {
      dispatch(actions.change(entityModel, props.value))
    },
    resolveError: (matchId) => {
      dispatch(updateEntity('generateConsultationError', {
        id: ownProps.errorId,
        resolutionType: "resolved",
        matchId: matchId
      }))

      dispatch(upsertEntity('generateConsultationError', ownProps.errorId))
    },
    createDossierWithDefaults: () => {
      return dispatch(createEntityWithDefaults('dossier', { firstName: ownProps.summary }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ui({state: {showDropdown: false}})(GenerateConsultationErrorButtons));
