import DossierForm from 'components/dossiers/DossierForm';

import NewEntityModal from 'components/entities/NewEntityModal';
import React from 'react'
import { connect } from 'react-redux'
import { Dropdown, Icon } from 'semantic-ui-react'
import { createDossierWithDefaults } from "../../actions/dossiers";

class AddNewMenuItem extends React.Component {
  render() {
    let createEntityDefinitions = {
      'dossier': { form: DossierForm, createEntityWithDefaults: this.props.newDossierWithDefaults },
    }

    return (
      <Dropdown item trigger={<Icon name='plus square' size='large'/>}>
        <Dropdown.Menu>
          { Object.keys(createEntityDefinitions).map( (entityName) => {
            let createEntityDefinition = createEntityDefinitions[entityName]

            return (<NewEntityModal key={entityName} entityName={entityName} createEntityWithDefaults={createEntityDefinition.createEntityWithDefaults} form={createEntityDefinition.form} trigger={ (props) => {

              return (<Dropdown.Item as={'a'} text={I18n.t(`components.add_new_menu_item.dropdown_items.${entityName}`)} key={entityName} onClick={props.onClick} />)
            }} />) }) }
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    newDossierWithDefaults: () => {
      return dispatch(createDossierWithDefaults())
    }
  }
}

export default connect(null, mapDispatchToProps)(AddNewMenuItem);
