import ContactForm from 'components/contacts/ContactForm'
import ContactsListItem from 'components/contacts/ContactsListItem'

import EntitiesList from 'components/entities/EntitiesList';
import { connect } from 'react-redux'

import { getContacts } from 'selectors/contacts'
import { Card } from 'semantic-ui-react'
import { createContactWithDefaults } from "../../actions/contacts";

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getContacts(state),
    entityName: 'contact',
    item: ContactsListItem,
    form: ContactForm,
    listComponent: Card.Group,
    loading: state.meta.contact?.loading,
    error: state.meta.contact?.error
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createEntityWithDefaults: () => {
      return dispatch(createContactWithDefaults())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList)
