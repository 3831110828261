import TimelineNode from 'components/dossiers/dossierFollowUp/TimelineNode';
import React from 'react'

import { connect } from 'react-redux'

import { findEntity } from 'selectors/entities';
import { Image } from "semantic-ui-react";
import { Button, Divider, Grid, Header, Icon, Item, Label, Menu, Segment } from 'semantic-ui-react';
import { createEntityWithDefaults } from "../../../actions/entities";
import NewEntityModal from "../../entities/NewEntityModal";
import EditModal from "../../forms/EditModal";
import RichTextRender from "../../forms/richText/RichTextRender";
import PrintableComponent, { getPrintButton } from "../../printing/PrintableComponent";
import DossierItemForm from "./DossierItemForm";
import SimpleAttachmentForm from "./SimpleAttachmentForm";

export class ConsultationNode extends React.Component {

  render() {
    const {isSelected, nodeStyle, dossierItem} = this.props
    const {attachmentId, text, time, noteId} = dossierItem
    const hasNotes = (text != null)
    let hrefString = attachmentId &&  Routes.download_api_dossier_item_path({ id: attachmentId, _options: true })

    return (
      <TimelineNode {...this.props} time={time} icon='calendar outline'>
        <PrintableComponent ref={element => (this.printRef = element)}>
        <Item as={Segment} raised={isSelected} clearing contentEditable={false} style={nodeStyle}>
          {this.renderConsultation(hasNotes)}
          { hasNotes ? this.renderNotes(text) : null}
          { attachmentId ? this.renderAttachment(hrefString) : null}
        </Item>
        </PrintableComponent>
      </TimelineNode>
    )
  }

  renderConsultation(hasNotes) {
    const {patient, consultation, attributes } = this.props
    const {time} = this.props.dossierItem

    if (!consultation || !patient) {
      return <div>{I18n.t('components.dossier_item.not_loaded')}</div>
    }

    const consultationType = consultation.consultationType
    return <div {...attributes}>
      <Item.Content>
        <Label corner='left'>
          <Icon name={(consultationType || {}).icon || 'question'} size='big'/>
        </Label>
        <Grid divided>
          <Grid.Row>
            <Grid.Column width={4} textAlign='center'>
              {patient ?
                <Header>{(consultationType || {}).name || I18n.t('components.consultations_list.unknown_consultation_type')}</Header> :
                <Header/>}
              <Item.Description>{I18n.l("date.formats.short_human_readable", time)}</Item.Description>
            </Grid.Column>
            <Grid.Column className="dont-print">
            </Grid.Column>
            <Grid.Column width={5} floated='right' textAlign='right' className="dont-print">
              <Menu compact borderless secondary>
                <Menu.Item>
                  {this.renderNotesButton(hasNotes)}
                </Menu.Item>
                <Menu.Item>
                  {this.renderAttachmentButton()}
                </Menu.Item>
                <Menu.Item>
                  {getPrintButton(this.printRef)}
                </Menu.Item>
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Item.Content>
    </div>
  }

  renderNotes(text) {
    return (
      <Item.Content>
        <Divider/>
        <RichTextRender content={text}/>
      </Item.Content>)
  }

  renderAddButton(props) {
      return (
        <Button onClick={props.onClick}>
          <Icon name='plus'/>
          {I18n.t(`components.dossier_item.add`)}
        </Button>
      )
  }

  renderEditButton(props) {
      return (
        <Button onClick={props.onClick} icon='pencil'/>
      )
  }

  renderNotesButton(hasNotes) {
      return (
        [<NewEntityModal entityName="dossierItem" form={DossierItemForm} refreshAfterSave={true}
                         createEntityWithDefaults={this.props.createEntityWithDefaults} key={'addButton'}
                         trigger={(props) => { return hasNotes ? null: this.renderAddButton(props)}
        }
        />,
        <EditModal entityName="dossierItem" EntityForm={DossierItemForm} entityId={this.props.dossierItem.noteId}
                   refreshAfterSave={true} key={'editButton'}
                   trigger={(props) => { return hasNotes ? this.renderEditButton(props): null }}
        />]
      )
  }

  renderAttachmentButton() {
    return(
      <NewEntityModal entityName="dossierItem"
                      createEntityWithDefaults={this.props.createAttachmentWithDefaults}
                      form={SimpleAttachmentForm} trigger={(props) => {
        return (<Button onClick={props.onClick} icon="attach"/>)
      }}
      />
    )
  }

  renderAttachment(hrefString) {
    let url = this.props.dossierItem.attachmentExpiringUrl

      return (
        <Item.Content>
          <Divider/>
          { this.isImage(url) ?
            <Image src={url} size="medium" href={hrefString}/> :
            <Header size="small" href={hrefString}>
              {this.props.dossierItem.attachmentTitle}
            </Header> }
        </Item.Content>)

  }

  isImage(url) {
    if(typeof url !== 'string') return null
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

}

const mapStateToProps = (state, ownProps) => {
  let consultation = findEntity(state, 'consultation', ownProps.consultationId)
  let dossier = findEntity(state, 'dossier', consultation && consultation.dossierId)
  let patient = findEntity(state, 'contact', dossier && dossier.patientId)

  return {
    consultation: consultation,
    patient: patient,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  let dossierId = ownProps.dossierItem.dossierId
  return {
    createEntityWithDefaults: () => {
      return dispatch(createEntityWithDefaults('dossierItem', {dossierItemType: "consultation", consultationId: ownProps.consultationId, dossierId: dossierId, time: new Date().toISOString()}))
    },
    createAttachmentWithDefaults: () => {
      return dispatch(createEntityWithDefaults('dossierItem', {
        dossierItemType: "attachment",
        dossierId: dossierId,
        consultationId: ownProps.consultationId,
        time: new Date().toISOString()
      }))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationNode)
