import _ from 'lodash'
import orm from 'models'
import { createSelector as ormSelector } from "redux-orm/lib/redux";
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import getEntities from 'selectors/entities'

const createDeepEqualSelector = createSelectorCreator(defaultMemoize,
  _.isEqual
)

const getDossierId = (state, props) => props.dossierId

const getDossier = (state, props) => {
  if (getDossierId(state, props)) {
    return orm.session(getEntities(state, props)).dossier.withId(getDossierId(state, props))
  } else {
    return null
  }
}

const getConsultations = ormSelector(orm, getEntities, (session) => {
  return session.consultation.all().toModelArray().map(consultation => {
    const {ref} = consultation
    return {
      ...ref,
      dossier: session.dossier.withId(consultation.dossierId),
      consultationType: session.consultationType.withId(consultation.consultationTypeId),
      event: session.event.withId(consultation.eventId)
    }
  })
})

const getConsultationsForDossier = createSelector([getConsultations, getDossierId],
  (consultations, dossierId) => {
    return consultations.filter(consultation => consultation.dossier && consultation.dossier.id === dossierId)
  }
)

export const getDossierItemsForDossier = createSelector([getDossier], (dossier) => {
  if (!dossier) {
    return []
  }

  return dossier.dossierItems.toModelArray().map(item => {
    const {ref} = item
    return {
      ...ref,
      consultation: item.consultation
    }
  })
})

const getDossierSlicesForDossier = createSelector([getDossier], (dossier) => {
  if (!dossier) {
    return []
  }

  return dossier.dossierSlices.toRefArray()
})

export const getDossierItemsJson = createDeepEqualSelector([getDossier, getDossierSlicesForDossier, getDossierItemsForDossier, getConsultationsForDossier], (dossier, slices, items, consults) => {
  if (!dossier) {
    return "{}"
  }

  let dossierItem = {id: `dossier_${dossier.id}`, dossierItemType: 'dossier', dossierId: dossier.id}
  let unsortedItems = [];

  let notesPerConsult = {};
  let attachmentsPerConsult = {};
  let plainItems = [];

  items.forEach((item) => {
      if (item.consultationId) {
        if(item.dossierItemType === 'attachment') {
          attachmentsPerConsult[item.consultationId] = item
        } else {
          notesPerConsult[item.consultationId] = item
        }
      } else
        plainItems.push(item);
    }
  )

  consults.forEach((consult) => {
    let note = notesPerConsult[consult.id];
    let attachment = attachmentsPerConsult[consult.id];

    let startTime;
    if (consult.event) {
      startTime = consult.event.startTime
    } else {
      startTime = (note && note.time) || (attachment && attachment.time)
    }
    unsortedItems.push({
      ...consult,
      id: `cons_${consult.id}`,
      consultationId: consult.id,
      dossierId: dossier.id,
      time: startTime,
      dossierItemType: 'consultation',
      text: note && note.text,
      noteId: note && note.id,
      attachmentId: attachment && attachment.id,
      attachmentExpiringUrl: attachment && attachment.attachmentExpiringUrl,
      attachmentTitle: attachment && attachment.attachmentTitle,
      creatorId: consult.practioner && consult.practioner.id
    })
  })

  plainItems.forEach((item) => {
    unsortedItems.push({
      ...item,
      id: item.id,
      dossierId: dossier.id,
      time: item.time,
      creatorId: item.creator && item.creator.id
    })
  })

  slices.forEach((slice) => {
    unsortedItems.push({
      id: `slice_${slice.id}`,
      dossierItemType: 'slice',
      sliceId: slice.id,
      dossierId: dossier.id,
      time: slice.startTime
    })
  })

  unsortedItems = _.orderBy(unsortedItems, 'time', 'desc');

  return [dossierItem, ...unsortedItems]
})
