import React from 'react'
import { connect } from "react-redux";
import { List, Segment } from 'semantic-ui-react'
import { getCurrentOffice } from "../../../selectors/offices";
import AttachmentNode from "./AttachmentNode";
import ConsultationNode from "./ConsultationNode";
import DossierNode from "./DossierNode";
import DossierSliceNode from "./DossierSliceNode";
import NoteNode from "./NoteNode";

class DossierItemListItem extends React.Component {

  render() {

    const dossierItem = this.props.dossierItem
    return (
      <List.Item key={dossierItem.id} as={Segment} basic clearing>
        <List.Content>
          {this.renderNode(dossierItem)}
        </List.Content>
      </List.Item>
    )
  }

  renderNode = (node) => {
    const { applyNodeStyle } = this.props

    let nodeColor = node.creatorId === 74 ? '#eb144c' : '#9fe1e7'

    let nodeStyle = applyNodeStyle ?
      { borderTop: '4px', borderTopColor: nodeColor, borderTopStyle: 'solid' } :
      null


    switch(node.dossierItemType) {
      case 'consultation':
        return <ConsultationNode key={node.key} consultationId={node.consultationId} {...this.props} nodeStyle={nodeStyle}/>
      case 'slice':
        return <DossierSliceNode key={node.key} sliceId={node.sliceId} {...this.props}/>
      case 'dossier':
        return <DossierNode key={node.key} dossierId={node.dossierId} {...this.props}/>
      case 'note':
        return <NoteNode time={node.time} {...this.props} nodeStyle={nodeStyle}/>
      case 'attachment':
        return <AttachmentNode time={node.time} {...this.props} nodeStyle={nodeStyle}/>
      default:
        return <h1>no node</h1>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let office = getCurrentOffice(state)
  let applyNodeStyle = office.hiddenPreferences && office.hiddenPreferences.includes("colorDossierNodes")

  return {
    applyNodeStyle: applyNodeStyle,
  }
}

export default connect(mapStateToProps, null)(DossierItemListItem);


